import Layout from '../../components/Navigation/layout'
import * as React from "react";
import {FC, useEffect, useState} from "react";
import AccountPage from "../../PageTypes/AccountPage/AccountPage";
import {Client, handleApiError} from "../../services/ApiService";
import {Member} from "../../interfaces/Member";
import {connect, ConnectedProps} from "react-redux";
import Loading from "../../components/Loading/Loading";
import {setMemberID} from '../../stores/member/memberSlice';

const mapState = ({member, auth}) => ({
    member,
    auth
})

const mapDispatch = {
    setMemberID
}

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>

const UserPage: FC<PropsFromRedux> = (props) => {
    const [user, setUser] = useState<Member>(null);

    useEffect(() => {
        if (props.member && props.auth.Key) {
            Client.Member.current(props.auth.Key)
                .then((res) => {
                    setUser(res.data);
                    props.setMemberID(res.data.ID);
                })
                .catch(handleApiError);
        }
    }, [props])

    return (
        <Layout pageTitle="Mein Profil">
            {user ?
                <AccountPage user={user}/>
                : <Loading type={"border"} message={"Daten werden geladen."}/>
            }
        </Layout>
    )
}

export default connector(UserPage);
