import * as React from "react";
import {FC, useEffect, useState} from "react";
import AccountBanner from "../../components/AccountBanner/AccountBanner";
import {Dropdown, Tab, Tabs} from "react-bootstrap";
// @ts-ignore
// @ts-ignore
import * as style from './AccountPage.module.scss';
import {customContainer, navTabs} from './AccountPage.module.scss';
import Orders from "../../components/Orders/Orders";
import ProfileData from "../../components/ProfileData/ProfileData";
import {Member} from "../../interfaces/Member";
import {faBoxOpen, faHeart, faMapMarkerAlt} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Address from "../../components/Address/Address";

type AccountPageProps = {
    user: Member
}

const AccountPage: FC<AccountPageProps> = ({user}) => {
    const [key, setKey] = useState('orders');
    const [selection, setSelection] = useState('Bestellungen');

    useEffect(() => {
        if(window.location.toString().includes("tab=MyData")) {
            setKey("profile");
            setSelection("Meine Daten")
        }
    }, [])

    function getCurrentDisplay() {
        switch (selection) {
            case 'Bestellungen':
                return <Orders type={"member"}/>
            case 'Meine Daten':
                return <ProfileData user={user}/>
            case 'Adresse':
                return <Address user={user}/>
            default:
                return <Orders type={"member"}/>
        }
    }

    return (
        <>
            <AccountBanner user={user}/>
            <div className={customContainer + ' ' + navTabs + ' d-none d-lg-block'}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="orders"
                         title={<><FontAwesomeIcon icon={faBoxOpen} className={style.icon}/> Bestellungen </>}>
                        <Orders type={"member"}/>
                    </Tab>
                    {/*<Tab eventKey="bookmarks"*/}
                    {/*     title={<><FontAwesomeIcon icon={faUserCircle} className={style.icon}/> Merkliste </>}>*/}
                    {/*    <h1> Test </h1>*/}
                    {/*</Tab>*/}
                    <Tab eventKey="profile"
                         title={<><FontAwesomeIcon icon={faHeart} className={style.icon}/> Meine Daten </>}>
                        <ProfileData user={user}/>
                    </Tab>
                    <Tab eventKey="address"
                         title={<><FontAwesomeIcon icon={faMapMarkerAlt} className={style.icon}/> Adresse </>}>
                        <Address user={user}/>
                    </Tab>
                </Tabs>
            </div>
            <div className={customContainer + ' ' + navTabs + ' d-block d-lg-none'}>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className={style.fakeTab}>
                        {selection}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={style.fakeHolder}>
                        <Dropdown.Item onClick={() => setSelection('Bestellungen')}>
                            <FontAwesomeIcon icon={faBoxOpen} className={style.icon + ' me-2'} />
                            Bestellungen
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelection('Meine Daten')}>
                            <FontAwesomeIcon icon={faHeart} className={style.icon + ' me-2'} />
                            Meine Daten
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setSelection('Adresse')}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className={style.icon + ' me-2'} />
                            Adresse
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {getCurrentDisplay()}
            </div>
        </>
    );
}

export default AccountPage
