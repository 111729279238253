import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Button, Col, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Member} from "../../interfaces/Member";
import * as yup from 'yup';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Client, handleApiError} from "../../services/ApiService";
// @ts-ignore
import * as mainStyle from "../../theme/main.module.scss";
import {connect, ConnectedProps} from "react-redux";
// @ts-ignore
import * as style from './EditSensibleData.module.scss';
import {faCheck} from "@fortawesome/pro-light-svg-icons";
import {toast} from "react-toastify";

const mapState = ({auth}) => ({
    auth
})
const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type EditSensibleDataProps = PropsFromRedux & {
    user: Member
}

interface FormInputs {
    Password: string,
    PasswordRetype: string,
}

const schema = yup.object().shape({
    Password: yup.string().required('Bitte gib ein Passwort ein.').min(8, 'Das Passwort muss mindestestens 8 Zeichen lang sein.'),
    PasswordRetype: yup.string().required('Bitte wiederhole das Passwort.').oneOf([yup.ref('Password'), null], 'Die Passwörter stimmen nicht überein.'),
})

const EditSensibleData: FC<EditSensibleDataProps> = ({user, auth}) => {
    const [userData, setUserData] = useState([]);
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    function onPasswordSubmit(data) {
        Client.Auth.authPut(auth.Key, 'Member/', user.ID, data).then((res) => {
            toast.success('Passwort erfolgreich geändert!');
        }).catch(handleApiError);
    }

    return (
        <Form onSubmit={handleSubmit(onPasswordSubmit)}>
            <Col lg={6}>
                <Form.Group controlId="formPassword">
                    <Form.Label className={style.inputLabel}>Passwort</Form.Label>
                    <Form.Control type="password" className={style.inputField} {...register('Password')}/>
                    <Form.Text className={mainStyle.errorMsg}>
                        {errors.Password?.message}
                    </Form.Text>
                </Form.Group>
            </Col>
            <Col lg={6}>
                <Form.Group controlId="formPasswordRetype">
                    <Form.Label className={style.inputLabel}>Passwort wiederholen</Form.Label>
                    <Form.Control type="password" className={style.inputField} {...register('PasswordRetype')}/>
                    <Form.Text className={mainStyle.errorMsg}>
                        {errors.PasswordRetype?.message}
                    </Form.Text>
                </Form.Group>
            </Col>
            <Button type={"submit"} className={style.submitButton}>
                <FontAwesomeIcon icon={faCheck} className={style.icon}/>
                Speichern
            </Button>
        </Form>
    )
}

export default connector(EditSensibleData);
