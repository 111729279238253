import * as React from "react";
import {FC} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {Member} from "../../interfaces/Member";
import EditSensibleData from "./EditSensibleData";
import EditMainData from "./EditMainData";
// @ts-ignore
import * as style from './ProfileData.module.scss';
import * as mainStyle from "../../theme/main.module.scss";
import EditNewsletterStatus from "./EditNewsletterStatus";
type ProfileDataProps = {
    user: Member
}

const ProfileData: FC<ProfileDataProps> = ({user}) => {
    return (
        <Row className="pt-5 pb-5">
            <Col lg={6} xs={12}>
                <h2 className={style.heading}> Meine Kontaktdaten </h2>
                <EditMainData user={user}/>
            </Col>
            <Col lg={6} xs={12} className={'mt-5 mt-lg-0'}>
                <h2 className={style.heading}> Passwort ändern</h2>
                <EditSensibleData user={user}/>
                <EditNewsletterStatus user={user}/>
            </Col>
        </Row>
    )
}

export default ProfileData;
