import * as React from "react";
import {FC, Fragment, useEffect, useState} from "react";
import {Button, Col, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Member} from "../../interfaces/Member";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Client, handleApiError} from "../../services/ApiService";
// @ts-ignore
import * as mainStyle from "../../theme/main.module.scss";
import {connect, ConnectedProps} from "react-redux";
// @ts-ignore
import * as style from './EditMainData.module.scss';
import {faCheck} from "@fortawesome/pro-light-svg-icons";
import Loading from "../Loading/Loading";
import {toast} from "react-toastify";
import UploadField from "../UploadField/UploadField";
import {navigate} from "gatsby";

const mapState = ({registration, member, auth}) => ({
    member,
    auth,
    registration
})

const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type EditMainDataProps = PropsFromRedux & {
    user: Member
}

interface FormInputs {
    FirstName: string,
    Surname: string,
    Email: string,
    Company: string,
    Birthdate: any,
    Salutation: any
}

const schema = yup.object().shape({
    Company: yup.string().optional(),
    FirstName: yup.string().required('Bitte gib einen Vornamen an.'),
    Surname: yup.string().required('Bitte gib einen Nachnamen an.'),
    Email: yup.string().required('Bitte gib eine E-Mail Adresse an.').email('Die angegebene E-Mail Adresse ist ungültig'),
    Birthdate: yup.string().nullable().optional(),
    Salutation: yup.string().nullable().optional()
})

const EditMainData: FC<EditMainDataProps> = ({registration, user, member, auth}) => {
    const [userData, setUserData] = useState([]);
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    function onMainSubmit(data) {
        Client.Auth.authPut(auth.Key, 'Member', user.ID, data).then((res) => {
            if (registration.IdDocument && registration.IdDocument.length > 0) {
                Client.Member.deleteIdDocument(auth.Key, user.ID, {}).then(() => {
                    Client.Member.addIdDocument(auth.Key, user.ID, {IdDocumentID: registration.IdDocument[0].ID}).then(() => {
                        toast.success('Daten erfolgreich geändert!');
                        navigate('/user/vendor');
                    }).catch(handleApiError);
                })
            } else {
                Client.Member.deleteIdDocument(auth.Key, user.ID, {}).then(() => {
                    toast.success('Daten erfolgreich geändert!');
                })
            }
        }).catch(handleApiError);
    }

    return (
        <>
            {user && typeof user !== "undefined" ?
                <Form onSubmit={handleSubmit(onMainSubmit)}>
                    <Col lg={6}>
                        <Form.Group controlId="formCompany">
                            <Form.Label className={style.inputLabel}>Unternehmensname</Form.Label>
                            <Form.Control type="text" className={style.inputField}
                                          defaultValue={user?.Company} {...register('Company')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.Company?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>

                        <Form.Group controlId="formSalutation" className="mb-4">
                            <Form.Label className={style.inputLabel}>Anrede</Form.Label>
                            <div className={style.dropDownChevron}>
                                <Form.Control as="select"
                                              className={style.dropDown} {...register('Salutation')}>
                                    <option selected={user?.Salutation != 'Herr' && user?.Salutation != 'Frau'}
                                            value={""}></option>
                                    <option selected={user?.Salutation == 'Herr'} value={"Herr"}> Herr</option>
                                    <option selected={user?.Salutation == 'Frau'} value={"Frau"}> Frau</option>
                                </Form.Control>
                                <Form.Text className="errorMsg">
                                    {errors.Salutation?.message}
                                </Form.Text>
                            </div>
                        </Form.Group>

                    </Col>
                    <Col lg={6}>
                        <Form.Group controlId="formFirstName" className="mb-2">
                            <Form.Label className={style.inputLabel}>Dein Vorname</Form.Label>
                            <Form.Control type="text" className={style.inputField}
                                          defaultValue={user?.FirstName} {...register('FirstName')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.FirstName?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group controlId="formLastName">
                            <Form.Label className={style.inputLabel}>Dein Nachname</Form.Label>
                            <Form.Control type="text" className={style.inputField}
                                          defaultValue={user?.Surname} {...register('Surname')} />
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.Surname?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group controlId="formEmail">
                            <Form.Label className={style.inputLabel}>Deine E-Mail Adresse</Form.Label>
                            <Form.Control type="email" className={style.inputField}
                                          defaultValue={user?.Email} {...register('Email')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.Email?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col lg={6}>
                        <Form.Group controlId={"formAgePicker"}>
                            <Form.Label className={style.inputLabel}>Geburtsdatum eingeben</Form.Label>
                            <Form.Control type={"date"} defaultValue={user?.Birthdate} {...register('Birthdate')} />
                        </Form.Group>
                        <Form.Group controlId={"formAgeVerify"}>
                            <Form.Label className={style.inputLabel}>Ausweis hochladen</Form.Label>
                            <UploadField maxFiles={1} isIdDocument={true}/>
                        </Form.Group>
                    </Col>
                    <Button type={"submit"} className={style.submitButton}>
                        <FontAwesomeIcon icon={faCheck} className={style.icon}/>
                        Speichern
                    </Button>
                </Form>
                : <Loading type={"border"} message={"Daten werden geladen"}/>
            }
        </>
    )
}

export default connector(EditMainData);
