import React, {useEffect, useState} from "react";
import {FC, ReactElement} from "react";
// @ts-ignore
import * as mainStyle from "../../theme/main.module.scss";
// @ts-ignore
import * as style from './EditSensibleData.module.scss';
import {Client, handleApiError} from "../../services/ApiService";
import {toast} from "react-toastify";
import {Button, Form} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-light-svg-icons";

const mapState = ({auth}) => ({
    auth
})
const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

interface EditNewsletterStatusProps {
    user: any;
}

const EditNewsletterStatus: FC<EditNewsletterStatusProps & PropsFromRedux> = ({user, auth}): ReactElement => {
    const [newsletterStatus, setNewsletterStatus] = useState<boolean>(user.NewsletterStatus);
    const [newsletterText, setNewsletterText] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setNewsletterText(res.data[0].NewsletterText);
        }).catch(handleApiError);
    })

    function handleNewsletterChange(b: boolean) {
        setNewsletterStatus(b);
        Client.Auth.authPut(auth.Key, 'Member', user.ID, {NewsletterStatus: b}).then((res) => {
            toast.success(b ? 'Zum Newsletter angemeldet!' : 'Vom Newsletter abgemeldet!');
        }).catch(handleApiError);
    }

    return (
        <>
            <div>
                <h3 className={style.heading + ' mt-5'}> Newsletter</h3>
                <Form.Group controlId="formNewsletter">
                    <Form.Label className={style.inputLabel}>{newsletterText}</Form.Label>
                    <Form.Check
                        type="checkbox"
                        onChange={(e) => setNewsletterStatus(e.target.checked)}
                        id="custom-switch"
                        checked={newsletterStatus}
                        label={'Zum Newsletter anmelden'}
                    />
                    <Form.Text className={mainStyle.errorMsg}>

                    </Form.Text>
                </Form.Group>
                <Button type={"submit"} className={style.submitButton} onClick={() => handleNewsletterChange(newsletterStatus)}>
                    <FontAwesomeIcon icon={faCheck} className={style.icon}/>
                    Speichern
                </Button>
            </div>
        </>
    );
}

export default connector(EditNewsletterStatus);